window.axeptioSettings = {
    clientId: import.meta.env.VITE_AXEPTIO_CLIENT_ID,
    cookiesVersion: import.meta.env.VITE_AXEPTIO_COOKIE_VERSION,
    googleConsentMode: {
        default: {
            analytics_storage: 'denied',
            ad_storage: 'denied',
            ad_user_data: 'denied',
            ad_personalization: 'denied',
            wait_for_update: 500,
        },
    },
};


(function (d, s) {
    const t = d.getElementsByTagName(s)[0],
        e = d.createElement(s);
    e.async = true;
    e.src = "//static.axept.io/sdk.js";
    t.parentNode.insertBefore(e, t);
})(document, "script");


function loadHotjar() {
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:window.hotjar,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
}


function loadGTM() {
    (function(w,d,s,l,i){
        w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', window.ggtm); // Remplacez par votre ID GTM
}

loadGTM();

void 0 === window._axcb && (window._axcb = []);
window._axcb.push(function (axeptio) {
    axeptio.on("cookies:complete", function (choices) {
        if (choices.hotjar) {
            loadHotjar();
        }
    });
});
