const $offerButtons = document.querySelectorAll('.js-offers-button');
const $offerListing = document.querySelector('.js-offers-listing');


if($offerListing)
    $offerButtons.forEach($offerButton => {
        $offerButton.addEventListener('click', () => {
            $offerButtons.forEach($offerButton => {$offerButton.classList.remove('active')});
            $offerButton.classList.add('active');

            if($offerButton.hasAttribute('data-button-line')){
                $offerListing.classList.add('inline');
            }else{
                $offerListing.classList.remove('inline');
            }
        });
    });
