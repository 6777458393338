// Panel for mobile
const $menuButtons = document.querySelectorAll(".js-menu-button");
const $menu = document.querySelector(".js-navigation");

$menuButtons.forEach(($menuButton) => {
    if ($menu)
        $menuButton.addEventListener("click", () => {
            if ($menu.classList.contains("active")) {
                document.body.style.overflow = "auto";
            } else {
                document.body.style.overflow = "hidden";
            }
            $menu.classList.toggle("active");
        });
});

// Menu items
const $menuItems = document.querySelectorAll(
    '.js-navigation-item[data-has-children="true"]'
);
$menuItems.forEach(($menuItem) => {
    const $link = $menuItem.querySelector("a");
    const handleClickOutside = function (e) {
        if (!$menuItem.contains(e.target)) {
            $menuItem.classList.remove("active");
        }
    };

    if ($link)
        $link.addEventListener("click", (event) => {
            event.preventDefault();
            if ($menuItem.classList.contains("active")) {
                window.removeEventListener("click", handleClickOutside);
            } else {
                window.addEventListener("click", handleClickOutside);
            }
            $menuItem.classList.toggle("active");
        });
});
